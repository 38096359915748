import React from 'react';
import { useQuery } from '@apollo/client';
import { Tab } from 'semantic-ui-react';
import Loading from '../../Loading';
import Recordings from './Recordings';
import Participants from './Participants';
import Summaries from './Summaries';
import { MEETING_REPORT } from '../../../queries/meeting';

const MeetingReport = ({ jobId }) => {
  const { loading, data } = useQuery(MEETING_REPORT, {
    variables: { id: jobId },
  });
  if (loading) {
    return <Loading />;
  }
  const { meetingReport } = data.job.zoomMeeting;
  if (meetingReport.status === 'Complete') {
    const panes = [
      {
        menuItem: 'Meeting Summary',
        render: () => (
          <Tab.Pane attached={false}>
            <Summaries summaries={meetingReport.summaries} />
          </Tab.Pane>
        ),
      },
      {
        menuItem: 'Meeting Participants',
        render: () => (
          <Tab.Pane attached={false}>
            <Participants participants={meetingReport.participants} />
          </Tab.Pane>
        ),
      },
    ];
    return (
      <div id="meeting-report">
        <Recordings recordings={meetingReport.recordings} />
        <Tab className="menu-tab" menu={{ text: true }} panes={panes} />
      </div>
    );
  }
  return <pre>(None - Meeting did not take place)</pre>;
};

export default MeetingReport;
