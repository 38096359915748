import React from 'react';
import { Card, Label } from 'semantic-ui-react';
import ExpertInformation from './ExpertInformation';
import JobInformation from './JobInformation';
import JobActions from './JobActions';
import SearchHighlights from './SearchHighlights';

const STATUS_COLORS = {
  Invited: 'yellow',
  Upcoming: 'blue',
  Accepted: 'blue',
  Past: 'blue',
  Completed: 'teal',
};

const JobCard = ({ job, searchResult = null }) => (
  <Card className={`request ${searchResult && 'search-result'}`}>
    <Card.Content>
      <div className="status">
        <Label circular size="mini" color={STATUS_COLORS[job.status] || 'red'} empty />
        {` ${job.status}`}
      </div>
      <ExpertInformation job={job} />
      <Card.Description>
        <JobInformation job={job} />
      </Card.Description>
    </Card.Content>
    <Card.Content extra>
      {searchResult ? (
        <SearchHighlights searchResult={searchResult} />
      ) : (
        <JobActions job={job} />
      )}
    </Card.Content>
  </Card>
);

export default JobCard;
