import { gql } from '@apollo/client';
import { MEETING_BASIC_INFO } from './meeting';

export const EXPERT_BASIC_DATA = `
  id
  fullName
  country
  title
  profile_picture
  organization {
    id
    name
    type
  }
  functions
  medical_specialties
  technology_tags
  credits
  numCompleted
  teamData {
    avgRating
    numEngagements
  }
`;

const EXPERT_PROFILE = gql`
  query Expert($id: String!) {
    expert(id: $id) {
      ${EXPERT_BASIC_DATA}
      infoFromLinkedin
      description
      user {
        id
        upcomingMeetings {
          id
        }
        pendingJobs {
          id
        }
        pastJobs {
          id
          expert_rating
        }
      }
    }
  }
`;

export const EXPERT_JOBS_HISTORY = gql`
  query User($id: String!) {
    user(id: $id) {
      id
      pendingJobs {
        ${MEETING_BASIC_INFO}
      }
      currentJobs {
        ${MEETING_BASIC_INFO}
      }
      pastJobs {
        ${MEETING_BASIC_INFO}
      }
    }
  }
`;

export const SEARCH_EXPERTS = gql`
  query Search($query: String!, $filters: [SearchFilter], $exclusions: [SearchExclusion], $page: Int) {
    search(query: $query, filters: $filters, exclusions: $exclusions, page: $page) {
      total
      items {
        item {
          __typename
          ... on Expert {
            ${EXPERT_BASIC_DATA}
            user {
              id
              pendingJobs {
                id
              }
            }
          }
        }
      }
      facets {
        id
        categoryId
        name
        items {
          value
          count
        }
      }
    }
  }
`;

export const COUNT_EXPERTS = gql`
  query Search($query: String!, $filters: [SearchFilter], $exclusions: [SearchExclusion]) {
    search(query: $query, filters: $filters, exclusions: $exclusions) {
      total
    }
  }
`;

export default EXPERT_PROFILE;
