import React from 'react';
import { Table, Icon } from 'semantic-ui-react';
import moment from 'moment';

const Participants = ({ participants }) => (
  <Table basic="very" padded stackable>
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell>
          <Icon name="user" />
          Name
        </Table.HeaderCell>
        <Table.HeaderCell>
          <Icon name="sign in" />
          Join time
        </Table.HeaderCell>
        <Table.HeaderCell>
          <Icon name="sign out" />
          Leave time
        </Table.HeaderCell>
        <Table.HeaderCell>
          <Icon name="stopwatch" />
          Duration
        </Table.HeaderCell>
      </Table.Row>
    </Table.Header>
    <Table.Body>
      {participants.map(
        (participant) => (
          <Table.Row key={`${participant.name}${participant.joinedAt}`}>
            <Table.Cell>{participant.name}</Table.Cell>
            <Table.Cell textAlign="center">
              {moment(participant.joinedAt).format('MM/DD/YYYY, hh:mm:ss A')}
            </Table.Cell>
            <Table.Cell textAlign="center">
              {moment(participant.leftAt).format('MM/DD/YYYY, hh:mm:ss A')}
            </Table.Cell>
            <Table.Cell>{`${participant.numMinutes} minute(s)`}</Table.Cell>
          </Table.Row>
        ),
      )}
    </Table.Body>
  </Table>
);

export default Participants;
