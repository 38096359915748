import React, { useState, useContext } from 'react';
import { Modal, List, Icon } from 'semantic-ui-react';
import momentTZ from 'moment-timezone';
import MeetingSettings from './MeetingSettings';
import ZoomInfo from './ZoomInfo';
import ExpertInfo from './ExpertInfo';
import InterviewerInfo from './InterviewerInfo';
import DataContext from '../../contexts/DataContext';

const MeetingModal = ({ children, job }) => {
  const { user } = useContext(DataContext);
  const [open, setOpen] = useState(false);

  const { expert, project, meetingAt } = job;
  const meetingTime = momentTZ.tz(meetingAt, user.timeZone).format('LLLL z');
  const expertName = job.user.fullName || job.user.email;

  return (
    <Modal
      closeIcon
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      trigger={children}
      id="meeting-modal"
    >
      <Modal.Header>
        {project.title}
        {job.overall_status === 'Scheduled' && <MeetingSettings job={job} />}
      </Modal.Header>
      <Modal.Content>
        <List>
          <List.Item>
            <Icon name="clock outline" />
            <List.Content>{meetingTime}</List.Content>
          </List.Item>
          <InterviewerInfo meeting={job} />
          <ExpertInfo
            name={expertName}
            title={expert.title}
            organization={expert.organization.name}
          />
          {open && <ZoomInfo job={job} />}
        </List>
      </Modal.Content>
    </Modal>
  );
};

export default MeetingModal;
