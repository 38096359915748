import React from 'react';
import {
  List, Button, Input, Icon,
} from 'semantic-ui-react';
import CopyLinkButton from '../CopyLinkButton';
import MeetingInvite from './MeetingInvite';
import MeetingReport from './MeetingReport';
import Job from '../../models/Job';

const ZoomInfo = ({ job }) => {
  const isUpcoming = new Job(job).status === 'Upcoming';
  const { meetingLink } = job.zoomMeeting;
  if (isUpcoming) {
    if (!meetingLink) {
      return (
        <>
          <List.Item>
            <List.Content>
              <List.Header>
                <Icon name="warning circle" />
                No meeting information available at the moment.
              </List.Header>
              <List.Description>
                We will email you once a meeting is scheduled.
              </List.Description>
            </List.Content>
          </List.Item>
        </>
      );
    }
  }
  return (
    <>
      <List.Item>
        <List.Content>
          <List.Description>
            {isUpcoming ? <MeetingInvite jobId={job.id} /> : <MeetingReport jobId={job.id} />}
          </List.Description>
        </List.Content>
      </List.Item>
      {
            isUpcoming
              && (
                <List.Item>
                  <List.Content id="zoom-info-btns">
                    <Button
                      className="primary-btn"
                      onClick={() => { window.open(meetingLink, '_blank'); }}
                    >
                      Join Zoom Meeting
                    </Button>

                    <Input
                      label={(
                        <CopyLinkButton
                          content="Copy Link"
                          className="basic-btn"
                          copyText={meetingLink}
                        />
                    )}
                      labelPosition="right"
                      readOnly
                      value={meetingLink}
                    />
                  </List.Content>
                </List.Item>
              )
          }
    </>
  );
};
export default ZoomInfo;
