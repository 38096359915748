import React from 'react';
import {
  Card, Label, Grid,
} from 'semantic-ui-react';
import ProfileModal from './ProfileModal';
import ProfilePicture from './ProfilePicture';
import ProfileLabel from '../ProfileLabel';
import RequestButton from '../Meetings/RequestButton';
import ExpertListsButton from '../Lists/ExpertListsButton';
import CustomLink from '../CustomLink';
import ExpertCardRating from './ExpertCardRating';

const ExpertCard = ({ expert }) => {
  const allTags = [...expert.medical_specialties, ...expert.functions,
    ...expert.technology_tags, expert.organization.type].filter((x) => x);
  const uniqueTags = allTags.filter((tag, idx) => allTags.indexOf(tag) === idx);

  return (
    <Card className="expert-card">
      <Card.Content>
        <Card.Header>
          <ProfileModal expertId={expert.id}>
            <CustomLink>
              <ProfilePicture expert={expert} />
              {expert.fullName}
            </CustomLink>
          </ProfileModal>
        </Card.Header>
        <Grid>
          <Grid.Column style={{ paddingLeft: 0 }}>
            <Card.Meta>
              {`${expert.title}, ${expert.organization.name}`}
              <br />
              {expert.country}
            </Card.Meta>
            <ExpertCardRating expert={expert} />
          </Grid.Column>
        </Grid>
        <Card.Description>
          <Grid>
            <Grid.Row>
              <ProfileLabel num={expert.credits} type="credit" />
              <ProfileLabel num={expert.numCompleted || 0} type="call" />
            </Grid.Row>
            <Grid.Row className="button-row">
              <RequestButton expert={expert} />
            </Grid.Row>
            <Grid.Row className="button-row">
              <ExpertListsButton expert={expert} />
            </Grid.Row>
          </Grid>
        </Card.Description>
      </Card.Content>
      {uniqueTags.length > 0 && (
        <Card.Content extra className="tags-section">
          {uniqueTags.map((t) => <Label key={t} size="small">{t}</Label>)}
        </Card.Content>
      )}
    </Card>
  );
};

export default ExpertCard;
