import React from 'react';
import { List, Icon, Popup } from 'semantic-ui-react';
import Security from '../../utils/Security';

const InterviewerInfo = ({ meeting }) => {
  const isInterviewer = Security.isInterviewer(meeting);
  const isTeamMember = Security.isTeamMember(meeting.project.team);
  return (
    <>
      {(isInterviewer || isTeamMember) && (
      <List.Item>
        <List.Icon name="user" />
        <List.Content>
          <List.Header>
            {'Interviewer(s): '}
            <Popup inverted trigger={<Icon name="info circle" />} position="bottom left">
              This information is visible only to you and your team.
              Dexter does not share interviewer information with interviewees.
            </Popup>
          </List.Header>
          <List.Description>{` ${meeting.interviewers.map((i) => i.fullName || i.email).join(', ')}`}</List.Description>
        </List.Content>
      </List.Item>
      )}
    </>
  );
};

export default InterviewerInfo;
