// Assuming the content is always sanitized HTML to disable the eslint warning
/* eslint-disable react/no-danger */
import React, { useState } from 'react';
import {
  Grid, List, Icon, Modal, Header,
} from 'semantic-ui-react';

const Highlight = ({ searchResult }) => {
  const [open, setOpen] = useState(false);
  return (
    <Modal
      closeIcon
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      trigger={<Icon name="angle right" size="large" color="black" link />}
    >
      <Modal.Header>{`${searchResult.item.user.fullName} "${searchResult.query}" matches`}</Modal.Header>
      <Modal.Content className="search-highlights">
        {searchResult.highlights.map((highlight) => (
          <div key={highlight.field}>
            <Header as="h4">{highlight.field}</Header>
            {highlight.segments.map((segment) => (
              <p key={segment} dangerouslySetInnerHTML={{ __html: segment }} />
            ))}
            <br />
          </div>
        ))}
      </Modal.Content>
    </Modal>
  );
};

const SearchHighlights = ({ searchResult }) => (
  <Grid columns={2}>
    <Grid.Column width={14} className="card-highlight-content">
      <List className="search-highlights">
        {searchResult.highlights.map((highlight) => (
          <List.Item key={highlight.field}>
            <p>
              <strong>{`${highlight.field} (${highlight.segments.length}): `}</strong>
              <span dangerouslySetInnerHTML={{ __html: highlight.segments[0] }} />
            </p>
          </List.Item>
        ))}
      </List>
    </Grid.Column>
    <Grid.Column
      width={2}
      textAlign="center"
      verticalAlign="middle"
    >
      <Highlight searchResult={searchResult} />
    </Grid.Column>
  </Grid>
);

export default SearchHighlights;
