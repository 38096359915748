import React from 'react';
import { List } from 'semantic-ui-react';

const ExpertInfo = ({ name, title, organization }) => (
  <List.Item>
    <List.Icon name="user" />
    <List.Content>
      <List.Header>{'Expert: '}</List.Header>
      <List.Description>{` ${name} | ${title} at ${organization}`}</List.Description>
    </List.Content>
  </List.Item>
);

export default ExpertInfo;
