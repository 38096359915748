import React from 'react';
import { Header } from 'semantic-ui-react';

const Summaries = ({ summaries }) => {
  if (summaries.length === 0) {
    return '(The summary is not available for this meeting)';
  }
  return summaries.map((summary) => (
    <div key={summary.title}>
      <Header as="h4">{summary.title}</Header>
      <br />
      <br />
      <div>
        <Header as="h5">Overview</Header>
        <p>{summary.overview}</p>
      </div>
      <br />
      {summary.details.map((detail) => (
        <div key={detail.label}>
          <Header as="h5">{detail.label}</Header>
          <p>{detail.summary}</p>
          <br />
        </div>
      ))}
    </div>
  ));
};

export default Summaries;
