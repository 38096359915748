import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import {
  Header, Button, Form, Input, Icon,
} from 'semantic-ui-react';
import Loading from '../../Loading';
import ProjectShareModal from '../ProjectShareModal';
import { PROJECT_JOBS } from '../../../queries/project';
import NoProjectJobs from './NoProjectJobs';
import ProjectJobs from './ProjectJobs';
import SearchJobs from './SearchJobs';

const ManageProjectJobs = ({ project: basicProject }) => {
  const [query, setQuery] = useState('');
  const [submittedSearchQuery, setSubmittedSearchQuery] = useState('');

  const { loading, data } = useQuery(PROJECT_JOBS, {
    variables: { id: basicProject.id },
  });

  if (loading) return <Loading />;

  const projectLinkButton = (
    <ProjectShareModal project={data.project}>
      <Button className="basic-btn">Invite my contacts</Button>
    </ProjectShareModal>
  );

  return (
    <div id="manage-project-jobs">
      <div className="search-section">
        <Form onSubmit={() => setSubmittedSearchQuery(query)}>
          <Input
            placeholder="Search engagements"
            label={(<Button icon><Icon name="search" /></Button>)}
            labelPosition="right"
            onChange={(e, { value }) => setQuery(value)}
          />
        </Form>
      </div>
      {submittedSearchQuery ? <SearchJobs project={data.project} query={submittedSearchQuery} /> : (
        <div>
          <div className="top-share-section">
            {projectLinkButton}
          </div>
          {
            data.project.jobs.length === 0
              ? <NoProjectJobs project={data.project} />
              : <ProjectJobs project={data.project} />
            }
          <div className="bottom-share-section">
            <Header size="large">
              Got experts?
            </Header>
            <div className="description">
              <Header.Subheader>
                Invite your own contacts to the project.
              </Header.Subheader>
              <Header.Subheader>
                Dexter will handle everything else - screening, scheduling, and payments
              </Header.Subheader>
            </div>
            {projectLinkButton}
          </div>
        </div>
      )}
    </div>
  );
};

export default ManageProjectJobs;
