import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import {
  Loader, Card, Header, Grid, List, Checkbox,
} from 'semantic-ui-react';
import { SEARCH_JOBS } from '../../../queries/job';
import Job from '../../../models/Job';
import JobCard from './JobCard';

const SearchJobs = ({ project, query }) => {
  const [exclusions, setExclusions] = useState([]);
  const { data, loading } = useQuery(SEARCH_JOBS, {
    fetchPolicy: 'network-only',
    variables: {
      projectId: project.id,
      query,
      exclusions,
    },
  });
  if (loading) return <Loader active />;
  const { total, items, facets } = data.searchProjectData;
  return (
    <div className="search-jobs-result">
      <Grid stackable columns={2}>
        <Grid.Column verticalAlign="middle">
          <Header as="h4">{`${total} "${query}" results`}</Header>
        </Grid.Column>
        <Grid.Column>
          <List horizontal>
            {facets[0].items.map(({ value: facet }) => (
              <List.Item key={facet}>
                <Checkbox
                  label={facet}
                  checked={exclusions.indexOf(facet) === -1}
                  onClick={(e, { checked }) => {
                    if (checked) {
                      setExclusions(exclusions.filter((f) => f !== facet));
                    } else {
                      setExclusions(exclusions.concat(facet));
                    }
                  }}
                />
              </List.Item>
            ))}
          </List>
        </Grid.Column>
      </Grid>
      <div className="requests">
        <Card.Group itemsPerRow={2} stackable>
          {items.map(
            (i) => (
              <JobCard
                key={i.item.id}
                job={{ ...new Job(i.item), project }}
                searchResult={{
                  ...i,
                  query,
                }}
              />
            ),
          )}
        </Card.Group>
      </div>
    </div>
  );
};

export default SearchJobs;
