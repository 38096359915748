import React from 'react';
import { Button, Header, List } from 'semantic-ui-react';

const MeetingRecordings = ({ recordings }) => (
  <div>
    <Header as="h5">Meeting Recordings</Header>
    <List>
      {recordings.map((x) => (
        <Button
          key={`${x.contentType}${Math.random()}`}
          size="small"
          onClick={() => {
            if (['Video', 'Audio'].includes(x.contentType)) {
              window.open(x.playUrl);
            }
            if (x.contentType === 'Transcript') {
              window.open().document.write(`<pre>${x.content}</pre>`);
            }
          }}
        >
          {x.contentType}
        </Button>
      ))}
    </List>
  </div>
);

export default MeetingRecordings;
