import React, { useContext } from 'react';
import {
  Grid, Card, Pagination, Header,
} from 'semantic-ui-react';
import FilterCategory from '../components/Search/FilterCategory';
import ProjectExclusion from '../components/Search/ProjectExclusion';
import ExpertCard from '../components/Experts/ExpertCard';
import SearchContext, { SearchProvider } from '../contexts/SearchContext';

const Search = () => (
  <SearchProvider>
    <SearchPage />
  </SearchProvider>
);

const SearchPage = () => {
  const {
    searchResult, query, appliedFilters, appliedExclusions,
    page, paginate, clearFilters, toggleFilter,
  } = useContext(SearchContext);

  if (!query) return <div>No search term.</div>;

  const totalPages = Math.ceil(searchResult.total / 9);

  const renderExperts = () => (
    <Card.Group itemsPerRow={3} stackable>
      {searchResult.items.map((e) => <ExpertCard key={e.item.id} expert={e.item} />)}
    </Card.Group>
  );

  return (
    <Grid stackable id="search">
      <Grid.Row className="stats">
        {`Showing ${searchResult.total} results for "${query}"`}
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={4} id="filter-section">
          <Grid.Row>
            <Grid
              columns="equal"
              className="heading"
            >
              <Grid.Column>
                <Header as="h4" className="text">
                  Filters
                </Header>
              </Grid.Column>
              {(appliedFilters.length + appliedExclusions.length > 0) && (
                <Grid.Column textAlign="right">
                  <Header as="h4" className="span-link">
                    <span role="button" tabIndex={0} onClick={clearFilters} onKeyDown={clearFilters}>Clear All</span>
                  </Header>
                </Grid.Column>
              )}
            </Grid>
          </Grid.Row>
          <Grid.Row>
            <Grid stackable className="categories">
              {searchResult.facets.map((facet) => {
                const currFilters = appliedFilters.find((x) => x.categoryId === facet.categoryId);
                const selected = currFilters ? currFilters.values : [];
                if (facet.items.length > 0) {
                  return (
                    <div className="filter-category" key={facet.id}>
                      <FilterCategory
                        facet={facet}
                        selected={selected}
                        toggleFilter={toggleFilter}
                      />
                    </div>
                  );
                }
                return '';
              })}
              {<ProjectExclusion />}
            </Grid>
          </Grid.Row>
        </Grid.Column>
        <Grid.Column width={12} id="search-results">
          <Grid>
            <Grid.Column>
              {renderExperts()}
            </Grid.Column>
          </Grid>
          <div className="pagination">
            <Pagination
              defaultActivePage={page}
              totalPages={totalPages}
              onPageChange={(e, d) => paginate(d.activePage)}
            />
          </div>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default Search;
