import { gql } from '@apollo/client';

export const JOB_BASIC_DATA = `
  id
`;

export const PROJECT_JOB = `
  id
  updatedAt
  meetingAt
  completedAt
  zoomMeeting {
    meetingLink
  }
  expert_rating
  overall_status
  acceptProbabilities
  qualtricsContact {
    responseId
  }
  qualificationResponses {
    question
    response
  }
  user {
    id
    email
    fullName
  }
  interviewers {
    id
    email
    fullName
  }
  expert {
    id
    title
    organization {
      name
      type
    }
    country
    profile_picture
    credits
    technology_tags
    medical_specialties
    stripe_account
  }
  bonuses {
    id
    credits
    description
    createdAt
  }
`;

const JOB = gql`
  query Job($id: String!) {
    job(id: $id) {
      ${JOB_BASIC_DATA}
    }
  }
`;

export const UPDATE_JOB = gql`
  mutation UpdateJob($userId: ID!, $projectId: ID!, $job: JobInput!) {
    updateJob(userId: $userId, projectId: $projectId, job: $job) {
      id
    }
  }
`;

export const INVITE_EXPERT_TO_PROJECT = gql`
  mutation InviteExpertToProject($userId: ID!, $projectId: ID!) {
    inviteExpertToProject(userId: $userId, projectId: $projectId) {
      id
      project {
        id
        numPendingInvites
        jobs {
          id
          overall_status
          user {
            id
          }
        }
        team {
          id
          availableInvites
        }
      }
    }
  }
`;

export const SEARCH_JOBS = gql`
  query SearchProjectData($projectId: ID!, $query: String!, $exclusions: [String]) {
    searchProjectData(projectId: $projectId, query: $query, exclusions: $exclusions) {
      total
      facets {
        items {
          value
        }
      }
      items {
        highlights {
          field
          segments
        }
        item {
          __typename
          ... on Job {
            ${PROJECT_JOB}
          }
        }
      }
    }
  }
`;

export default JOB;
