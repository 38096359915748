import React from 'react';
import { useQuery } from '@apollo/client';
import { Header } from 'semantic-ui-react';
import Loading from '../Loading';
import { MEETING_INVITE } from '../../queries/meeting';

const MeetingInvite = ({ jobId }) => {
  const { loading, data } = useQuery(MEETING_INVITE, {
    variables: { id: jobId },
  });
  if (loading) return <Loading />;
  return (
    <div>
      <Header as="h5">Meeting Information</Header>
      <pre>
        {data.job.zoomMeeting.meetingInvite}
      </pre>
    </div>
  );
};

export default MeetingInvite;
